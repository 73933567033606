import React from "react";

import Layout from "@rocketseat/gatsby-theme-docs/src/components/Layout";

import { useStaticQuery, graphql, Link } from "gatsby";

export default function NotFound() {
  const data = useStaticQuery(graphql`
    query {
      files: allFile(filter: { extension: { in: ["mdx"] } }) {
        edges {
          node {
            id
            relativePath
            childMdx {
              fields {
                slug
              }
              frontmatter {
                title
                description
              }
            }
          }
        }
      }
    }
  `);
  const posts = data.files.edges
    .map((edge) => ({
      id: edge.node.id,
      path: edge.node.childMdx.fields.slug,
      title: edge.node.childMdx.frontmatter.title,
      description: edge.node.childMdx.frontmatter.description,
    }))
    .filter(
      (post) =>
        post.path.startsWith("/01/") ||
        post.path.startsWith("/02/") ||
        post.path.startsWith("/03/") ||
        post.path.startsWith("/04/") ||
        post.path.startsWith("/05/") ||
        post.path.startsWith("/06/") ||
        post.path.startsWith("/07/") ||
        post.path.startsWith("/08/") ||
        post.path.startsWith("/09/") ||
        post.path.startsWith("/10/") ||
        post.path.startsWith("/11/") ||
        post.path.startsWith("/12/")
    );

  const [filter, setFilter] = React.useState("");

  const filteredPosts = posts.filter(
    (post) =>
      (post.title || "").toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
      (post.description || "").toLowerCase().indexOf(filter.toLowerCase()) > -1
  );

  return (
    <Layout title="Search">
      <div css={{ marginBottom: `10px` }}>
        <input
          type="search"
          placeholder="Enter your search criteria ..."
          css={{
            padding: `3px 15px 3px 8px`,
            border: `solid 1px #c7c7c7`,
            borderRadius: `3px`,
            fontSize: `1.1rem`,
            width: `100%`,
          }}
          value={filter}
          onChange={(e) => {
            setFilter(e.currentTarget.value);
          }}
        />
      </div>
      {filter &&
        filteredPosts.map((post) => (
          <div key={post.id} css={{ padding: `5px 0` }}>
            <Link to={post.path}>{post.title}</Link>
          </div>
        ))}
    </Layout>
  );
}
